export const headshotAdjustments = {
    0: { leftAdjust: -30, topAdjust: 60},
    1: { leftAdjust: -45, topAdjust: 60 },
    2: { leftAdjust: -47, topAdjust: 60 },
    3: { leftAdjust: -22, topAdjust: 60 },
    4: { leftAdjust: -45, topAdjust: 62 },
    5: { leftAdjust: -45, topAdjust: 60 },
    6: { leftAdjust: -20, topAdjust: 60 },
    7: { leftAdjust: -44, topAdjust: 60 },
    8: { leftAdjust: -45, topAdjust: 60 },
    9: { leftAdjust: -45, topAdjust: 60 },
    10: { leftAdjust: -50, topAdjust: 100 },
    11: { leftAdjust: -50, topAdjust: 75 },
    12: { leftAdjust: -42, topAdjust: 90 },
    13: { leftAdjust: -50, topAdjust: 86 }, // clones
    14: { leftAdjust: -50, topAdjust: 86 }, // clones
    15: { leftAdjust: -50, topAdjust: 70 },
    16: { leftAdjust: -60, topAdjust: 95 },
    17: { leftAdjust: -50, topAdjust: 60 },
    18: { leftAdjust: -55, topAdjust: 68 },
    19: { leftAdjust: -60, topAdjust: 68 },
    20: { leftAdjust: -65, topAdjust: 100 },
    21: { leftAdjust: -60, topAdjust: 60},
    22: { leftAdjust: -70, topAdjust: 60 },
    23: { leftAdjust: -85, topAdjust: 80}, //awowo
    24: { leftAdjust: -85, topAdjust: 80},
    25: { leftAdjust: -65, topAdjust: 60}, 
    26: { leftAdjust: -65, topAdjust: 60},
    27: { leftAdjust: -45, topAdjust: 60},
  };

  export const backgroundAdjustments = {
    0: { leftAdjust: -170, topAdjust: -20, scale: 2 },
    1: { leftAdjust: -300, topAdjust: 5, scale: 2 },
    2: { leftAdjust: -250, topAdjust: -10, scale: 2 },
    3: { leftAdjust: -500, topAdjust: -250, scale: 3 },
    4: { leftAdjust: -450, topAdjust: -300, scale: 3 },
    5: { leftAdjust: -10, topAdjust: 5, scale: 2 },
    6: { leftAdjust: 0, topAdjust: 10, scale: 1 },
    7: { leftAdjust: -230, topAdjust: -300, scale: 2 },
    8: { leftAdjust: -10, topAdjust: -5, scale: 2 },
    9: { leftAdjust: -500, topAdjust: -80  , scale: 3 },
    10: { leftAdjust: -200, topAdjust: 5, scale: 2 },
    11: { leftAdjust: -300, topAdjust: -100, scale: 2 },
    12: { leftAdjust: -10, topAdjust: 5, scale: 2 },
    13: { leftAdjust: -230, topAdjust: -50, scale: 2 },
    14: { leftAdjust: -480, topAdjust: -20, scale: 5 },
    15: { leftAdjust: -750, topAdjust: -170, scale: 3},
    16: { leftAdjust: -750, topAdjust: -200, scale: 5 },
    17: { leftAdjust: -750, topAdjust: -80, scale: 3 },
    18: { leftAdjust: -450, topAdjust: -250 , scale: 3 },
    19: { leftAdjust: -700, topAdjust: -250, scale: 3 },
    20: { leftAdjust: -425, topAdjust: -200, scale: 20 },
    21: { leftAdjust: -225, topAdjust: -200, scale: 2 },
    22: { leftAdjust: -550, topAdjust: -150, scale: 3 },
    23: { leftAdjust: -10, topAdjust: 5, scale: 2 },
    24: { leftAdjust: -10, topAdjust: 5, scale: 2 },
  };